











































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import EmailEditor from '../components/EmailEditor.vue';
import { FETCH_RENDERED_EMAIL_TEMPLATE } from '../store';
import { Email, ReceiverType, RenderEmailParams } from '../types';
import { ApiResponse } from '@/components/types';

const EmailTemplate = namespace('correspondence');
@Component({
  components: { EmailEditor }
})
export default class EmailCreate extends Vue {
  @EmailTemplate.Action(FETCH_RENDERED_EMAIL_TEMPLATE) fetchRendered!: (email: RenderEmailParams) => Promise<ApiResponse<Email>>;

  error: any = null;
  loading: boolean = false;
  email: Email | null = null;

  get conservatorship(): string {
    return this.$route.query['conservatorship'] as string;
  }

  get financeAccount(): string {
    return this.$route.query['financeAccount'] as string;
  }

  get contactPerson(): string {
    return this.$route.query['contactPerson'] as string;
  }

  get receiverType(): ReceiverType {
    return this.$route.params['receiverType'] as ReceiverType;
  }

  get template(): string {
    return this.$route.params['template'];
  }

  get filename(): string {
    if (!this.email) return 'letter.pdf';

    const today = (new Date()).toISOString().split('T')[0];

    return `${slugify(`${this.email.receiver.name}-${today}`)}.pdf`;
  }

  async save(observer: any) {
    const result = await observer.validate();

    if (!this.email || result) return {};

    const tempLink = document.createElement('a') as HTMLAnchorElement;

    tempLink.href = `mailto:${this.email.receiver.email}?subject=${this.email.headline}`;
    tempLink.click();

    return {};
  }

  async created() {
    const { error, content } = await this.fetchRendered({
      template: this.template,
      conservatorship: this.conservatorship,
      contact_person: this.contactPerson,
      finance_account: this.financeAccount
    });

    if (error) {
      this.error = error;
      return;
    }

    this.email = content || null;
  }
}
